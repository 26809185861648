import { PillButton } from '@troon/ui';
import { IconCloseCircle } from '@troon/icons';
import { StyleCard } from '../_style-card';

export default function PillButtonsPage() {
	return (
		<>
			<h1 class="text-3xl font-semibold">Pill buttons</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Primary">
					<div>
						<PillButton>Text</PillButton>
						<PillButton aria-pressed>
							Text <IconCloseCircle />
						</PillButton>
					</div>
				</StyleCard>
			</div>
		</>
	);
}
